<template>
  <div>
    <!--    Buttons    -->
    <div
      class="mb-1 d-flex flex-wrap"
      style="gap: .34rem"
    >
      <!--    Button: Cancel    -->
      <b-button
        variant="warning"
        size="sm"
        @click="$router.go(-1)"
      >
        <feather-icon icon="ArrowLeftIcon" />

        {{ $t('Return') }}
      </b-button>
    </div>

    <b-card>
      <!--     Form     -->
      <b-form>
        <b-row>
          <b-col sm="12"
                 lg="6"
          >
            <b-form-group :label="$t('EnterNameDocument')">
              <b-form-input v-model="editor.name" />
            </b-form-group>
          </b-col>

          <!-- Document Types -->
          <b-col sm="12"
                 lg="6"
          >
            <sw-select :name="$t('Contracts.AgreementType')">
              <v-select
                v-model="editor.agreementType"
                :options="types"
                label="name"
              >
                <template #no-options>
                  {{ $t('NoOptions') }}
                </template>
              </v-select>
            </sw-select>
          </b-col>

          <!-- Polls -->
          <b-col sm="12"
                 lg="6"
          >
            <sw-select
              v-if="checkRequiredModule('pollModule')"
              :name="$t('RelatedPolls')"
            >
              <v-select
                v-model="editor.assignedPolls"
                :options="polls"
                label="name"
                multiple
                @option:deselecting="deselectPoll"
              >
                <template #no-options>
                  {{ $t('NoOptions') }}
                </template>
              </v-select>
            </sw-select>
          </b-col>

          <!-- Signer users -->
          <b-col sm="12"
                 lg="6"
          >
            <sw-select :name="$t('UsersAllowedToSignContract')">
              <assigned-users
                :value="editor.signerUsers"
                @input="changeAssignedUsers"
              />
            </sw-select>
          </b-col>

          <b-col cols="12">
            <b-form-checkbox-group
              stacked
            >
              <b-form-checkbox
                v-model="editor.enableElectronicSignatureTypes"
                value="AUTENTI"
                class="custom-control-primary"
              >
                {{ $t('EnableAutentiSignature') }}
              </b-form-checkbox>

              <b-form-checkbox
                v-model="editor.enableElectronicSignatureTypes"
                value="WWW_SIGNATURE"
                class="custom-control-primary"
              >
                {{ $t('EnableHandwrittenSignature') }}
              </b-form-checkbox>
            </b-form-checkbox-group>
          </b-col>
        </b-row>
      </b-form>
    </b-card>
    <b-card>
      <b-form>
        <b-row>
          <!-- Header, Footer, Watermark -->
          <b-col sm="12"
                 md="6"
          >
            <b-form-group :label="$t('Header')">
              <b-form-textarea v-model="editor.headerText"
                               class="mr-25 mb-25"
                               rows="5"
              />
            </b-form-group>
            <b-row>
              <b-col sm="12"
                     md="6"
              >
                <b-form-group :label="$t('FontSize') + '  (' + $t('Header') + ')' ">
                  <b-form-input v-model="headerFont" />
                </b-form-group>
              </b-col>
              <b-col sm="12"
                     md="6"
              >
                <b-form-group>
                  <sw-form-input-color
                    :value="headerColor"
                    :label="$t('FontColor') + '  (' + $t('Header') + ')' "
                    height="65px"
                    fluid-x
                    @input="headerColor = $event.hex"
                  />
                </b-form-group>
                <!--                <b-form-group :label="$t('FontColor') + '  (' + $t('Header') + ')' ">-->
                <!--                  <b-form-input-->
                <!--                    v-model="headerColor"-->
                <!--                    type="color"-->
                <!--                    class="color-input"-->
                <!--                  />-->
                <!--                </b-form-group>-->
              </b-col>
            </b-row>
          </b-col>
          <b-col sm="12"
                 md="6"
          >
            <b-form-group :label="$t('Footer')">
              <b-form-textarea v-model="editor.footerText"
                               class="mr-25 mb-25"
                               rows="5"
              />
            </b-form-group>
            <b-row>
              <b-col sm="12"
                     md="6"
              >
                <b-form-group :label="$t('FontSize') + '  (' + $t('Footer') + ')' ">
                  <b-form-input v-model="footerFont" />
                </b-form-group>
              </b-col>
              <b-col sm="12"
                     md="6"
              >
                <b-form-group>
                  <sw-form-input-color
                    :value="footerColor"
                    :label="$t('FontColor') + '  (' + $t('Footer') + ')' "
                    height="65px"
                    fluid-x
                    @input="footerColor = $event.hex"
                  />
                </b-form-group>
              </b-col>
            </b-row>
          </b-col>

          <b-col sm="12"
                 class="d-flex flex-wrap"
          >
            <div class="mr-2 position-relative">
              <div class="d-flex justify-content-between align-items-center">
                <span>Logo ({{ $t('Header') }})</span>
                <b-button v-if="editor.headerLogo"
                          variant="flat-danger"
                          class="btn-icon"
                          size="sm"
                          @click="editor.headerLogo = ''"
                >
                  <sw-icon icon="TrashIcon" />
                </b-button>
              </div>
              <div class="dragDrop mb-25 rounded mt-25">
                <div>
                  <div v-if="editor.headerLogo"
                       class="position-relative"
                  >
                    <b-img
                      id="img-headerLogo"
                      class="px-0 py-0 center bg-cover"
                      style="object-fit: contain"
                      thumbnail
                      fluid
                      cover
                      :src="editor.headerLogo"
                    />
                  </div>
                  <div v-else
                       class="border d-flex align-items-center justify-content-center flex-column rounded"
                       style="min-width: 225px; min-height: 100px"
                  >
                    <feather-icon icon="UploadIcon"
                                  size="28"
                                  class="mb-50 text-primary"
                    />
                    {{ $t('DragDropFile') }}
                  </div>

                  <b-button
                    v-b-tooltip.hover.v-primary
                    variant="primary"
                    :title="$t('DragDropFile')"
                  >
                    <feather-icon icon="PlusIcon"
                                  size="28"
                    />
                    <input
                      type="file"
                      accept="image/*"
                      @change="onChange($event, 'headerLogo', 1250)"
                    >
                  </b-button>
                </div>
              </div>
            </div>

            <div class="mr-2">
              <div class="d-flex justify-content-between align-items-center">
                <span>Logo ({{ $t('Footer') }})</span>
                <b-button v-if="editor.footerLogo"
                          variant="flat-danger"
                          class="btn-icon"
                          size="sm"
                          @click="editor.footerLogo = ''"
                >
                  <sw-icon icon="TrashIcon" />
                </b-button>
              </div>
              <div class="dragDrop mb-25 rounded mt-25">
                <div>
                  <b-img
                    v-if="editor.footerLogo"
                    id="img-footerLogo"
                    class="px-0 py-0 center bg-cover"
                    style="object-fit: contain"
                    thumbnail
                    fluid
                    :src="editor.footerLogo"
                  />
                  <div v-else
                       class="border d-flex align-items-center justify-content-center flex-column rounded"
                       style="min-width: 225px; min-height: 100px"
                  >
                    <feather-icon icon="UploadIcon"
                                  size="28"
                                  class="mb-50 text-primary"
                    />
                    {{ $t('DragDropFile') }}
                  </div>

                  <b-button
                    v-b-tooltip.hover.v-primary
                    variant="primary"
                    :title="$t('DragDropFile')"
                  >
                    <feather-icon icon="PlusIcon"
                                  size="28"
                    />
                    <input
                      type="file"
                      accept="image/*"
                      @change="onChange($event, 'footerLogo', 1250)"
                    >
                  </b-button>
                </div>
              </div>
            </div>
            <div>
              <div class="d-flex justify-content-between align-items-center">
                <span>{{ $t('Watermark') }} <feather-icon v-b-tooltip
                                                          icon="AlertCircleIcon"
                                                          class="text-primary"
                                                          :title="$t('TransparentBackgroup')"
                /></span>
                <b-button v-if="editor.watermark"
                          variant="flat-danger"
                          class="btn-icon"
                          size="sm"
                          @click="editor.watermark = ''"
                >
                  <sw-icon icon="TrashIcon" />
                </b-button>
              </div>

              <div class="dragDrop mx-auto mt-25 mb-25">
                <div>
                  <b-img
                    v-if="editor.watermark"
                    id="img-watermark"
                    class="px-0 py-0 center bg-cover"
                    style="object-fit: contain"
                    thumbnail
                    fluid
                    :src="editor.watermark"
                  />
                  <div v-else
                       class="border d-flex align-items-center justify-content-center flex-column rounded"
                       style="min-width: 225px; min-height: 100px"
                  >
                    <feather-icon icon="UploadIcon"
                                  size="28"
                                  class="mb-50 text-primary"
                    />
                    {{ $t('DragDropFile') }}
                  </div>

                  <b-button
                    v-b-tooltip.hover.v-primary
                    variant="primary"
                    :title="$t('DragDropFile')"
                  >
                    <feather-icon icon="PlusIcon"
                                  size="28"
                    />
                    <input
                      type="file"
                      accept="image/*"
                      @change="onChange($event, 'watermark', 1250)"
                    >
                  </b-button>
                </div>
              </div>
            </div>
          </b-col>
        </b-row>
      </b-form>
    </b-card>

    <b-card>
      <!--     Description     -->
      <b-form-group :label="$t('task.Description')">
        <quill-editor
          v-model="editor.content"
          :options="editorOption"
        >
          <div
            id="toolbar"
            slot="toolbar"
          >
            <!-- Add a bold button -->
            <span class="ql-formats">
              <button class="ql-clean" />
              <button class="ql-bold" />
              <button class="ql-italic" />
              <button class="ql-link" />
            </span>
            <span class="ql-formats">
              <button
                class="ql-script"
                value="sub"
              />
              <button
                class="ql-script"
                value="super"
              />
            </span>
            <span class="ql-formats">
              <button
                class="ql-list"
                value="ordered"
              />
              <button
                class="ql-list"
                value="bullet"
              />
            </span>
            <span class="ql-formats">
              <button
                class="ql-indent"
                value="-1"
              />
              <button
                class="ql-indent"
                value="+1"
              />
            </span>
            <span class="ql-formats">
              <select class="ql-align" />
              <select class="ql-color" />
            </span>
            <span class="ql-formats">
              <select class="ql-size" />
              <select class="ql-header" />
              <select class="ql-font" />
            </span>

            <!-- You can also add your own -->
            <span class="ql-formats">
              <b-button
                id="custom-button"
                variant="success"
                class="w-auto"
                @click.prevent.stop="isModalOpen = true"
              >
                <span class="text-white">
                  <feather-icon
                    icon="CodeIcon"
                    class="mr-25"
                  />
                </span>

                <span class="text-white">
                  {{ $t('ShortCodes') }}
                </span>
              </b-button>
            </span>
          </div>
        </quill-editor>
      </b-form-group>
    </b-card>

    <!--     Save Button     -->
    <b-button
      variant="primary"
      :disabled="!editor.name || !editor.content || !editor.signerUsers.length"
      @click="saveContract"
    >
      {{ $t('Save') }}
    </b-button>

    <!--    <transition name="fade">-->
    <!--      <div-->
    <!--        v-if="scrollPos.y.value > 200"-->
    <!--        class="btn position-fixed mr-25"-->
    <!--        style="z-index: 999; bottom: 4.4rem; right: 0; transition-delay: .14s;"-->
    <!--      >-->
    <!--        <b-button-->
    <!--          variant="success"-->
    <!--          class="btn-icon rounded-circle"-->
    <!--          @click.prevent.stop="isModalOpen = true"-->
    <!--        >-->
    <!--          <feather-icon-->
    <!--            icon="CodeIcon"-->
    <!--            size="16"-->
    <!--          />-->
    <!--        </b-button>-->
    <!--      </div>-->
    <!--    </transition>-->

    <!--    Modal: Shortcode     -->
    <b-modal
      :visible="isModalOpen"
      :title="$t('Codes')"

      size="lg"
      hide-footer
      no-close-on-backdrop
      @hide="isModalOpen = false"
    >
      <shortcodes-modal
        :polls="editor.assignedPolls"
        :include-components="['PRODUCT', 'TRANCHE', 'CONTRACTOR', 'PAYMENT_INSTALLMENTS']"
        include-default-codes
        :default-shortcodes="[...signerUserShortcodes]"
        @close-modal="isModalOpen = false"
      />
    </b-modal>

    <b-overlay
      no-wrap
      :show="loading"
      spinner-variant="primary"
    />

    <!--    <div-->
    <!--      class="btn-scroll-to-top"-->
    <!--      :class="{'show': y > 250}"-->
    <!--    >-->
    <!--      <b-button-->
    <!--        variant="primary"-->
    <!--        class="btn-icon"-->
    <!--        @click="scrollToTop"-->
    <!--      >-->
    <!--        <feather-icon-->
    <!--          icon="ArrowUpIcon"-->
    <!--          size="16"-->
    <!--        />-->
    <!--      </b-button>-->
    <!--    </div>-->
  </div>
</template>

<script>
import { BForm, VBTooltip, BFormTextarea } from 'bootstrap-vue'
import vSelect from 'vue-select'
import { quillEditor } from 'vue-quill-editor'
import ShortcodesModal from '@/views/modals/ShortcodesModal.vue'
// eslint-disable-next-line
import 'quill/dist/quill.core.css'
// eslint-disable-next-line
import 'quill/dist/quill.snow.css'
import {
  CREATE_CONTRACT_TEMPLATE,
  GET_CONTRACT_TEMPLATE,
  GET_POLLS,
  UPDATE_CONTRACT_TEMPLATE,
} from '@/@constants/mutations'
import keysCuter from '@/helpers/keysCuter'
import AssignedUsers from '@/views/components/AssignedUsers.vue'
import { signerUserShortcodes } from '@/@data/shortcodes'
import axiosIns from '@/libs/axios'
import { useWindowScroll } from '@vueuse/core'
import { BFormCheckboxGroup } from 'bootstrap-vue/src/components/form-checkbox'

export default {
  name: 'ContractDetails',
  components: {
    BForm,
    quillEditor,
    ShortcodesModal,
    vSelect,
    BFormTextarea,
    AssignedUsers,
    BFormCheckboxGroup,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  data: () => ({
    signerUserShortcodes,
    loading: false,
    polls: [],
    types: [],

    footerFont: '8',
    footerColor: '#000000',
    headerFont: '8',
    headerColor: '#000000',

    editor: {
      name: '',

      watermark: '',

      footerText: '',
      footerLogo: '',
      footerStyle: '',

      headerText: '',
      headerLogo: '',
      headerStyle: '',

      assignedPolls: [],
      content: '',
      agreementType: '',
      paymentSchedule: false,
      advancePayment: false,
      signerUsers: [],
      paymentScheduleToPay: false,
    },
    isModalOpen: false,
    editorOption: {
      placeholder: '',
      modules: {
        toolbar: '#toolbar',
      },
    },
    scrollPos: 0,
  }),
  watch: {
    '$route.params.contractId': {
      deep: true,
      handler() { this.getTemplate() },
    },
    // eslint-disable-next-line
    'editor.paymentScheduleToPay'(newValue) {
      if (!newValue) this.editor.advancePayment = false
    },
  },
  mounted() {
    this.getPolls()
    this.getTypes()
    this.getTemplate()
    this.scrollPos = useWindowScroll()
  },
  methods: {
    async getTypes() {
      try {
        const resp = await axiosIns.get('1/settings/agreementTypes', { params: { fields_load: this.$fields.SETTINGS_AGREEMENTS_TYPES } })
        this.types = resp.data.data?.items || []
      } catch (err) {
        this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
      }
    },
    getTemplate() {
      if (this.$route.params?.contractId && this.$route.params.contractId !== 'new') {
        this.loading = true
        this.$nextTick(() => {
          this.$store.dispatch(`contractTemplates/${GET_CONTRACT_TEMPLATE}`, this.$route.params.contractId)
            .then(res => {
              this.$set(this, 'editor', res.data.item)

              if (res.data.item.footerStyle) {
                const { footerStyle } = res.data.item
                const stylesArray = footerStyle.split(';')
                stylesArray.forEach(style => {
                  if (style.includes('color')) {
                    const value = style.replaceAll('color:', '').replaceAll('px', '')
                    this.footerColor = value
                  } else if (style.includes('font-size')) {
                    const value = style.replaceAll('font-size:', '').replaceAll('px', '')
                    this.footerFont = value
                  }
                })
              }

              if (res.data.item.headerStyle) {
                const { headerStyle } = res.data.item
                const stylesArray = headerStyle.split(';')
                stylesArray.forEach(style => {
                  if (style.includes('color')) {
                    const value = style.replaceAll('color:', '').replaceAll('px', '')
                    this.headerColor = value
                  } else if (style.includes('font-size')) {
                    const value = style.replaceAll('font-size:', '').replaceAll('px', '')
                    this.headerFont = value
                  }
                })
              }

              this.loading = false
            })
            .catch(err => {
              this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
              this.loading = false
            })
        })
      } else if (this.$route.params?.contractId === 'new') {
        this.$set(this, 'editor', {
          name: '',
          assignedPolls: [],
          content: '',
          agreementType: '',
          paymentSchedule: false,
          advancePayment: false,
          signerUsers: [],
          watermark: '',
          footerText: '',
          footerLogo: '',
          headerText: '',
          headerLogo: '',
          paymentScheduleToPay: false,
        })
      }
    },
    getPolls() {
      this.$nextTick(() => {
        if (this.checkRequiredModule('pollModule')) {
          this.$store.dispatch(`polls/${GET_POLLS}`, {})
            .then(res => {
              this.polls = res.data.items
            })
            .catch(err => {
              this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
            })
        }
      })
    },
    deselectPoll(e) {
      this.showAlert('error', this.$i18n.t('alerts.AreYouSure'), this.$i18n.t('UsedPollShortcodesWillBeDeleted'))
        .then(() => {
          const pollId = e.id
          e.pollFields.sort(((a, b) => a.position - b.position)).forEach(field => {
            this.$set(this.editor, 'content', this.editor.content.replaceAll(`{% poll.${pollId}.${field.id}.name %}`, ''))
          })
        })
        .catch(() => {
          this.editor.assignedPolls.push(e)
        })
    },
    saveContract() {
      this.loading = true
      const payload = { ...this.editor }
      let action = CREATE_CONTRACT_TEMPLATE

      if (this.$route.params?.contractId && this.$route.params?.contractId !== 'new') {
        action = UPDATE_CONTRACT_TEMPLATE
        payload.id = this.$route.params.contractId
      }

      payload.assignedPolls = payload.assignedPolls.map(poll => (typeof poll === 'string' ? poll : poll?.id))
      payload.signerUsers = payload.signerUsers.map(user => (typeof user === 'string' ? user : user?.id))

      payload.agreementType = typeof payload.agreementType === 'string' ? payload.agreementType : payload.agreementType?.id

      payload.footerStyle = []
      payload.headerStyle = []

      if (this.footerColor) payload.footerStyle.push(`color:${this.footerColor}`)
      if (this.footerFont) payload.footerStyle.push(`font-size:${this.footerFont}px;line-height:${this.footerFont}px`)

      if (this.headerColor) payload.headerStyle.push(`color:${this.headerColor}`)
      if (this.headerFont) payload.headerStyle.push(`font-size:${this.headerFont}px;line-height:${this.headerFont}px`)

      payload.footerStyle = payload.footerStyle.join(';')
      payload.headerStyle = payload.headerStyle.join(';')

      this.$store.dispatch(`contractTemplates/${action}`, keysCuter(payload))
        .then(res => {
          if (action === CREATE_CONTRACT_TEMPLATE) this.$router.push({ name: 'contracts-generator', params: { contractId: res.data.items[0]?.id } })
          this.showToast('success', this.$i18n.t(`${action === CREATE_CONTRACT_TEMPLATE ? 'ContractTemplateCreated' : 'ContractTemplateUpdated'}`))
          this.loading = false
        })
        .catch(err => {
          this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
          this.loading = false
        })
    },
    changeAssignedUsers(payload) {
      this.editor.signerUsers = payload
    },
    onChange(event, option, maxSize) {
      if (event.target.files[0].size > maxSize * 1000) {
        this.showToast('danger', this.$i18n.tc('MoreThanKB', 1, {
          type: this.$i18n.t('SystemMoreThan.logo'),
          size: maxSize,
        }))
        return
      }
      const fr = new FileReader()
      fr.onload = () => {
        this.$set(this.editor, option, fr.result)
      }
      fr.readAsDataURL(event.target.files[0])
    },
  },
}
</script>

<style lang="scss">
.ql-editor { min-height: 16rem }

.color-input {
  appearance: none !important;
  -moz-appearance: none !important;
  -webkit-appearance: none !important;
  background: none !important;
  border: 0 !important;
  border-radius: 5px;
  cursor: pointer !important;
  height: 3rem !important;
  padding: 0 !important;
  margin: -2px 0 !important;
}
</style>
