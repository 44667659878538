<!--eslint-disable-->

<template>
  <b-row class="d-flex flex-row-reverse">
    <b-col
      sm="12"
      md="12"
      lg="12"
    >
      <!--    Button: New Contract    -->
      <b-button
        class="mb-50"
        variant="primary"
        size="sm"
        :to="{ name: 'contracts-generator', params: { contractId: 'new' } }"
      >
        <feather-icon
          icon="PlusIcon"
          class="mr-25"
        />
        {{ $t('Add') }}
      </b-button>
      <!--    Categories View    -->
      <div>
        <b-card class="myTableOffer">
          <sw-table
            table-id="f17a69fc2ad24c9f8ed238fdd82d6a0b"
            :pagination="pagination"
            @change-pagination="Object.assign(pagination, $event)"
            @reload-content="getContracts"
          >
            <template #table>
              <b-table
                :items="contracts"
                :fields="fields"
                :busy="loading"
                striped
                responsive
                show-empty
              >
                <!--    Table Head    -->
                <template #head()="item">
                  <span class="text-nowrap">
                    {{ $tc(item.field.label) | truncate(34, '...') }}
                  </span>
                </template>

                <!--    Name    -->
                <template #cell(name)="{ item }">
                  <router-link
                    v-b-tooltip.hover.left
                    :to="{ name: 'contracts-generator', params: { contractId: item.id } }"
                    :title="item.name"
                    @click="$emit('openEditModal', item)"
                  >
                    {{ item.name | truncate(64) }}
                  </router-link>
                </template>

                <!--    Status    -->
                <template #cell(status)="{ value }">
                  <!--    Sketches    -->
                  <b-badge variant="primary">
                    {{ $t('contractGenerator.status.' + value) }}
                  </b-badge>
                </template>

                <!--    Creator    -->
                <template #cell(createdBy)="{ value }">
                  <avatar
                    :user="value"
                    :size="28"
                  />
                </template>

                <!--    Table Action    -->
                <template #cell(action)="{ item }">
                  <b-dropdown
                    variant="link"
                    class="dropdown-icon-wrapper text-decoration-none"
                    no-caret
                  >
                    <template
                      #button-content
                      class="sr-only"
                    >
                      <feather-icon
                        icon="MoreVerticalIcon"
                        size="16"
                        class="align-middle"
                      />
                    </template>
                    <!--    Action: Edit    -->
                    <b-dropdown-item :to="{ name: 'contracts-generator', params: { contractId: item.id } }">
                      <span style="color: #6e6b7b">{{ $t('Edit') }}</span>
                    </b-dropdown-item>
                    <!--    Action: Edit    -->
                    <b-dropdown-item @click="deleteTemplate(item.id)">
                      {{ $t('Delete') }}
                    </b-dropdown-item>
                  </b-dropdown>
                </template>

                <!--    electronicSignatureType    -->
                <template #cell(enableElectronicSignatureTypes)="{ value }">
                  <div v-if="value.length" class="d-flex flex-wrap" style="gap: .34rem">
                    <b-badge
                      v-for="type in value"
                      :key="type"
                      variant="primary"
                      class="d-inline-flex align-items-center"
                      style="column-gap: .34rem"
                    >
                      <feather-icon :icon="type === 'WWW_SIGNATURE' ? 'Edit3Icon' : 'ShieldIcon'" />

                      {{ $t(`signatureTypes.${type}`) }}
                    </b-badge>
                  </div>

                  <feather-icon
                    v-else
                    icon="MinusIcon"
                  />
                </template>

                <!--    Other    -->
                  <!--    Other    -->
                  <template #cell()="row">
                      <table-default-cell
                              :field="row.field"
                              :value="row.value"
                      />
                  </template>

                  <!--      Empty      -->
                  <template #empty>
                      <empty-content />
                  </template>

                  <!--      Table Busy      -->
                  <template #table-busy>
                      <div class="text-center text-danger my-2">
                          <b-spinner
                                  class="align-middle"
                                  variant="primary"
                          />
                      </div>
                  </template>
              </b-table>
            </template>
          </sw-table>
        </b-card>
      </div>
    </b-col>
  </b-row>
</template>

<script>
/* eslint-disable */

import { BTable, VBTooltip } from 'bootstrap-vue'
import { DELETE_CONTRACT_TEMPLATE, GET_CONTRACTS_TEMPLATES } from '@/@constants/mutations'

export default {
  components: {
    BTable,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  data: () => ({
    fields: [
      { key: 'name', label: 'Name' },
      { key: 'agreementType', label: 'Contracts.AgreementType' },
      // { key: 'viewPDF', label: 'ViewPDF' },
      { key: 'enableElectronicSignatureTypes', label: 'TypesOfSignature' },
      { key: 'createdBy', label: 'CreatedBy' },
      { key: 'createdAt', label: 'offer.CreatedTime' },
      { key: 'action', label: 'Action' },
    ],

    loading: false,
    sorter: {
      sortBy: 'createdAt',
      sortDesc: true,
    },
    pagination: {
      perPage: 25,
      currentPage: 1,
      totalRecords: 100,
    },
    contracts: [],
  }),
  mounted() {
    this.getContracts()
  },
  methods: {
    getContracts() {
      this.loading = true
      this.$nextTick(() => {
        this.$store.dispatch(`contractTemplates/${GET_CONTRACTS_TEMPLATES}`, { pagination: this.pagination })
          .then(res => {
            this.contracts = res.data.items
            this.pagination.totalItemCount = res.data.totalItemCount
            this.loading = false
          })
          .catch(err => {
            this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
            this.loading = false
          })
      })
    },
    deleteTemplate(id) {
      this.showAlert('error', this.$i18n.t('alerts.AreYouSure'))
        .then(() => {
          this.$store.dispatch(`contractTemplates/${DELETE_CONTRACT_TEMPLATE}`, id)
            .then(() => {
              this.showToast('success', this.$i18n.t('RemovedSuccessfully'))
              this.getContracts()
            })
            .catch(err => {
              this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
            })
        })
    },
  },
}
</script>

<style lang="scss" scoped>
// .form-group { margin: 0 }

.stickyFilterBar {
  position: sticky;

  top: 7rem;
}
</style>
